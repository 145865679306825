import React, { useEffect, ReactElement } from 'react'
import { navigate } from 'gatsby'
import { useCookies } from 'react-cookie'

function getRedirectLanguage(cookieLang: string | undefined): string {
  if (cookieLang === undefined) {
    if (typeof navigator === `undefined`) {
      return 'en'
    }
    const lang =
      navigator && navigator.language && navigator.language.split('-')[0]
    if (!lang) {
      return 'en'
    }
    switch (lang) {
      case 'pl':
        return 'pl'
      default:
        return 'en'
    }
  } else {
    return cookieLang
  }
}

export default function IndexPage(): ReactElement {
  const cookies = useCookies(['lang'])[0]
  let cookieLang: string | undefined
  if (cookies.lang === 'pl') {
    cookieLang = 'pl'
  } else if (cookies.lang === 'en') {
    cookieLang = 'en'
  } else {
    cookieLang = undefined
  }
  useEffect(() => {
    const urlLang = getRedirectLanguage(cookieLang)
    navigate(`/${urlLang}/`, { replace: true })
  }, [])
  return <></>
}
